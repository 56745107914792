<div id="header">
  <div id="header-menu" class="site-settings">
    <img id="profile-picture" class="mobile" alt="Jannis Brinker" src="../assets/profile.png">
    <a id="menu-titel" href="#">Jannis Brinker</a>
    <a class="no-mobile" href="#contact">Contact</a>
    <a class="no-mobile" href="#references">References</a>
    <a class="no-mobile" href="#skills">Skills</a>
    <a class="no-mobile" href="#about">About</a>
  </div>
</div>
<div id="presentation">
  <img id="presentation-background" class="no-mobile" alt="" src="../assets/vct-m3-stage.png">
  <img id="presentation-profile-picture" class="no-mobile" alt="Jannis Brinker" src="../assets/profile.png">
</div>
<div id="about" class="site-settings" data-scroll-index="1">
  <h1>- About -</h1>
  <p>I'm Jannis, {{ age }} year old, passionate gamer and Esports enthusiast from Germany. On the internet I'm known under the pseudonym TheLuxorus also 
    called Lux. I work as a tournament manager, match ops lead, head referee, player manager  and team handler for online and offline events. I'm experienced in organizing and 
    running Esports tournaments at the highest standards. I have several years of experience in working on S-Tier tournaments.
    <br>
    <br>
    As the head referee of the VCT EMEA I face new challenges every day. I work closely with my referees, match operations and the broadcast team to ensure the integrity of 
    the VCT and to improve the show. Furthermore, I work in various roles for the VCT Global Events and as a freelancer for other Esports tournaments.
    <br>
    <br>
    In my spare time I travel around the world, do extreme sports and play videogames. One of my greatest passions is scuba diving. I love to capture the beauty of the 
    underwater world and to push myself to my limits while freediving. Beyond exciting travel adventures, I appreciate relaxing in nature while bivouacking.
    <br>
    <br>
    If you are interested in a collaboration or an interview, please contact me using the <a href="#contact">contact form</a>.
  </p>
  <div id="about-links">
    <a target="_blank" href="https://linkedin.com/in/jannis-brinker/"><img alt="LinkedIn" src="../assets/linkedin.png"></a>
    <a target="_blank" href="https://x.com/TheLuxorus"><img alt="X" src="../assets/x.png"></a>
  </div>
</div>
<div id="skills">
  <div id="skills-content" class="site-settings">
    <h1>- Skills -</h1>
    <div class="skills-frame">
      <div>
        <h2>Competitive Operations</h2>
        <p >I'm a experienced tournament manager and head referee. Among other things I take care of the creation of schedules, rulebooks and concepts. During the planning I meet with partners, 
          collaborators and the broadcast team. At live shows I ensure the integrity of the tournament.
        </p>
      </div>
      <div>
        <h2>Player Management</h2>
        <p>As a player manager I help set up teams logistics when they participate in events such as travel, accommodations, schedules, payment and more. When 
          a team has a problem they can't solve I'll support them in all matters.
        </p>
      </div>
      <div>
        <h2>Esport Team Handling</h2>
        <p>I'm the point of contact between the competitive operations and the pro team. As a team handler I communicate schedules, rule changes, decisions 
          and keep track of players. It's important to support and accompany the pro team in all their concerns.
        </p>
      </div>
      <div>
        <h2>Observering</h2>
        <p>I'm a observer for League of Legends. I observe, make replays and highlights during live games. I also create cinematic camera shots for videos, b-rolls and intros.</p>
      </div>    
      <div>
        <h2>Leadership</h2>
        <p>In order to implement projects successfully it's important to have a person in charge who keeps an eye on the goal. I'm excellent at leading people, meeting deadlines and 
          making impactful decisions.
        </p>
      </div>
      <div>
        <h2>Web Development</h2>
        <p>I develop web projects with Angular, TypeScript, JavaScript, C# and ASP.NET. Beside this I'm experienced with Firebase, Git, Visual Studio and Linux servers.</p>
      </div>
    </div> 
  </div>    
</div>
<div id="references">
  <div id="references-content" class="site-settings">
    <h1>- References -</h1>
    <h2>Competitive Operations</h2>
    <div class="references-frame">
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://redbull.com/"><img alt="" src="../assets/rb-hg.png"></a>
        </div> 
        <h3>RB Home Ground 2024</h3>
        <h4>RED BULL
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://spikenations.gg/"><img alt="" src="../assets/spike-nations.png"></a>
        </div>
        <h3>SPIKE NATIONS 2024</h3>
        <h4>GGTECH
          <br>
          <br>
          MATCH OPS LEAD
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-ascension.png"></a>
        </div>
        <h3>VCT ASCENSION EMEA 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://lolesports.com/"><img alt="" src="../assets/emea-masters.png"></a>
        </div>
        <h3>EMEA MASTERS SUMMER 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          SENIOR REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-emea-2024.png"></a>
        </div>
        <h3>VCT EMEA LEAGUE 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://lolesports.com/"><img alt="" src="../assets/lec.png"></a>
        </div>
        <h3>LEC SUMMER 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          SENIOR REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://lolesports.com/"><img alt="" src="../assets/emea-masters.png"></a>
        </div>
        <h3>EMEA MASTERS SPRING 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          SENIOR REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-masters-madrid.png"></a>
        </div>
        <h3>VCT MASTERS 1 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          SENIOR REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-emea-2024.png"></a>
        </div>
        <h3>VCT EMEA Kickoff 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://redbull.com/"><img alt="" src="../assets/rb-cc.png"></a>
        </div> 
        <h3>RB CC WORLDS FINAL</h3>
        <h4>RED BULL
          <br>
          <br>
          MATCH OPS LEAD
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://spikenations.gg/"><img alt="" src="../assets/spike-nations.png"></a>
        </div>
        <h3>SPIKE NATIONS 2023</h3>
        <h4>GGTECH
          <br>
          <br>
          MATCH OPS LEAD
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://redbull.com/"><img alt="" src="../assets/rb-cc.png"></a>
        </div>
        <h3>RB CC NATIONAL FINAL</h3>
        <h4>RED BULL
          <br>
          <br>
          REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://ggacademy.gg/"><img alt="" src="../assets/geng.png"></a>
        </div>
        <h3>EDUCATIONAL COURSES</h3>
        <h4>GEN.G GLOBAL ACADEMY
          <br>
          <br>
          GUEST SPEAKER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-champions-la.png"></a>
        </div>
        <h3>VCT CHAMPIONS 2023</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-emea-lcq-23.png"></a>
        </div>
        <h3>VCT EMEA LCQ 2023</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-ascension.png"></a>
        </div>
        <h3>VCT ASCENSION EMEA 2023</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-masters-tokyo.png"></a>
        </div>
        <h3>VCT MASTERS 2023</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-emea.png"></a>
        </div>
        <h3>VCT EMEA LEAGUE 2023</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-lockin.png"></a>
        </div>
        <h3>VCT LOCK//IN 2023</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-gc-champions.png"></a>
        </div>
        <h3>VCT GC CHAMPIONSHIP 2022</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-emea-lcq-22.png"></a>
        </div>
        <h3>VCT EMEA LCQ 2022</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          COMP OPS & HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-challengers.png"></a>
        </div>
        <h3>VCT CHALLENGERS EMEA 2022</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          COMP OPS & HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-gc.png"></a>
        </div>
        <h3>VCT GAME CHANGERS 2022</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          COMP OPS
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://vrldachevolution.gg/"><img alt="" src="../assets/vrl_unity.png"></a>
        </div>
        <h3>VRL DACH UNITY FINAL</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://dreamhack-hannover.de/"><img alt="" src="../assets/dh.png"></a>
        </div>
        <h3>DREAMHACK HANNOVER 2022</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          COMP OPS & VOLUNTEER COORDINATOR
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://vrldachevolution.gg/"><img alt="" src="../assets/vrl_evolution.png"></a>
        </div>
        <h3>VRL DACH EVOLUTION</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          COMP OPS CONSULTANT
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://nlc.gg/"><img alt="" src="../assets/nlc.png"></a>
        </div>
        <h3>NLC SUMMER FINAL 2022</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-masters-copenhagen.png"></a>
        </div>
        <h3>VCT MASTERS 2 2022</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-masters-reykjavik.png"></a>
        </div>
        <h3>VCT MASTERS 1 2022</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://battlefy.com/tectum-dachverband"><img alt="" src="../assets/tent.png"></a>
        </div>
        <h3>TENT TOURNAMENT 2018-2022</h3>
        <h4>RIOT COMMUNITY VOLUNTEERS
          <br>
          <br>
          TOURNAMENT MANAGER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://battlefy.com/rcv-shooting-stars"><img alt="" src="../assets/shooting-stars.png"></a>
        </div>
        <h3>SHOOTING STARS 2021</h3>
        <h4>RIOT COMMUNITY VOLUNTEERS
          <br>
          <br>
          TOURNAMENT ADMIN
        </h4>
      </div>
    </div> 
    <h2>Player Management</h2>
    <div class="references-frame">
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://lolesports.com/"><img alt="" src="../assets/lec.png"></a>
        </div>
        <h3>LEC SEASON FINALS 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          PLAYER MANAGER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://lolesports.com/"><img alt="" src="../assets/lec.png"></a>
        </div>
        <h3>LEC SUMMER 2024</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          PLAYER MANAGER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-champions-la.png"></a>
        </div>
        <h3>VCT CHAMPIONS 2023</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          TEAM HANDLER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-masters-tokyo.png"></a>
        </div>
        <h3>VCT MASTERS 2023</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          TEAM HANDLER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-champions-istanbul.png"></a>
        </div>
        <h3>VCT CHAMPIONS 2022</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          TEAM HANDLER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-champions-berlin.png"></a>
        </div>
        <h3>VCT CHAMPIONS 2021</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          TEAM HANDLER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/vct-masters.png"></a>
        </div>
        <h3>VCT MASTERS 3 2021</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          TEAM HANDLER
        </h4>
      </div>
    </div> 
    <h2>Observering</h2>
    <div class="references-frame">
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://equal-esports.com/"><img alt="" src="../assets/equal-esports-festival-3.png"></a>
        </div>
        <h3>EQUAL ESPORTS FESTIVAL 2023</h3>
        <h4>TELEKOM
          <br>
          <br>
          OBSERVER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://primeleague.gg/"><img alt="" src="../assets/prime-league.png"></a>
        </div>
        <h3>PRIME LEAGUE 2021-2022</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          BACKUP OBSERVER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://nlc.gg/"><img alt="" src="../assets/nlc.png"></a>
        </div>
        <h3>NLC 2022</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          BACKUP OBSERVER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://battlefy.com/tectum-dachverband"><img alt="" src="../assets/tent.png"></a>
        </div>
        <h3>TENT TOURNAMENT 2020-2021</h3>
        <h4>RIOT COMMUNITY VOLUNTEERS
          <br>
          <br>
          OBSERVER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://equal-esports.com/"><img alt="" src="../assets/equal-esports-festival.png"></a>
        </div>
        <h3>EQUAL ESPORTS FESTIVAL 2021</h3>
        <h4>TELEKOM
          <br>
          <br>
          OBSERVER
        </h4>
      </div>
    </div>
    <h2>Leadership</h2>
    <div class="references-frame">
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://valorantesports.com/"><img alt="" src="../assets/valorant.png"></a>
        </div>
        <h3>VALORANT ESPORTS</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          HEAD REFEREE
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://battlefy.com/tectum-dachverband"><img alt="" src="../assets/tectum.png"></a>
        </div>
        <h3>TECTUM 2018-2021</h3>
        <h4>RIOT COMMUNITY VOLUNTEERS
          <br>
          <br>
          PROJECT LEAD
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://twitter.com/RCVolus"><img alt="" src="../assets/rcv.png"></a>
        </div>
        <h3>RCV 2019-2021</h3>
        <h4>RIOT COMMUNITY VOLUNTEERS
          <br>
          <br>
          TEAM LEAD
        </h4>
      </div>
    </div> 
    <h2>Web Development</h2>
    <div class="references-frame">
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://riotgames.com/"><img alt="" src="../assets/riot-games.png"></a>
        </div>
        <h3>VCT EMEA CLOUD TIMER</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          DEVELOPER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://www.freaks4u.de/"><img alt="" src="../assets/f4u.png"></a>
        </div>
        <h3>GROUP DRAW TOOL</h3>
        <h4>FREAKS 4U GAMING
          <br>
          <br>
          DEVELOPER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://riotgames.com/"><img alt="" src="../assets/riot-games.png"></a>
        </div>
        <h3>WILD RIFT SCAVENGER HUNT</h3>
        <h4>RIOT GAMES
          <br>
          <br>
          DEVELOPER
        </h4>
      </div>
      <div>
        <div class="references-pictures">
          <a target="_blank" href="https://www.fp-francotyp.com/"><img alt="" src="../assets/francotyp-postalia.png"></a>
        </div>
        <h3>INTERNAL PROJECTS 2019-2021</h3>
        <h4>FRANCOTYP POSTALIA
          <br>
          <br>
          APPRENTICE SOFTWARE ENGINEER
        </h4>
      </div>
    </div> 
  </div>   
</div>
<div id="contact">
  <div id="contact-content" class="site-settings">
    <h1>- Contact -</h1>
    <form>
      <p class="contact-info">Please use the contact form or send an email to <a href="mailto:contact@luxorus.tech">contact@luxorus.tech</a></p>
      <label for="emailAddress"><p>Your E-Mail: <input disabled type="email" id="emailAddress" value="{{senderEmail}}" (keyup)="senderEmail = $any($event.target).value" required></p></label>
      <label for="emailSubject"><p>Subject: <input disabled type="text" id="emailSubject" value="{{emailSubject}}" (keyup)="emailSubject = $any($event.target).value" required></p></label>
      <textarea disabled style="text-align: center;" id="emailMessage" placeholder="Under maintenance ... please send me an email!" value="{{emailMessage}}" (keyup)="emailMessage = $any($event.target).value" required></textarea>
      <p class="contact-hint">(You will get a copy of your request)</p>
      <input disabled type="submit" id="emailSubmit" (click)="sendEmail()" value="Submit">
    </form>
  </div>
</div>
<div id="footer" class="site-settings">
  <p>© {{ currentYear }} Jannis Brinker</p> 
</div>